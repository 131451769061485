<template>
    <div class="line"></div>
</template>

<style scoped lang="scss">
.line {
    width: 80%;
    height: 1px;
    margin: 30px auto;
    background-color: lightgray;
}
</style>
