<template>
  <!-- eslint-disable max-len -->
  <div class="container">
    <div class="main-text">
      <div class="header">Your Favorite Streams</div>
      <div class="description">A convenient streaming app for online communication<br>and meeting
        new interesting
        people
      </div>
      <div class="shop_buttons">
        <a href="https://apps.apple.com/us/app/myfriends-live-stream-chat/id6449677455">
          <img class="shop" src="../assets/app-store-en.png" alt="App store"></a>
        <a href="https://play.google.com/store/apps/details?id=com.myfriends.live">
          <img class="shop" src="../assets/google-play-en.png" alt="Google play"></a>
      </div>
    </div>
    <div>
      <img alt="" class="phones" src="../assets/new_phones.png">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;
  border-radius: 0px 0px 20% 20%;
  color: white;
  background: linear-gradient(115.69deg, #20B270 22.71%, #22AB91 52.29%, #00A4B2 79.87%);

  .main-text {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
      font-size: 56px;
      font-weight: bold;
    }

    .description {
      font-size: 24px;
    }

    .shop_buttons {
      display: flex;
      gap: 5px;

      .shop {
      width: 200px;
      border-radius: 15px;
    }

    }
  }

  .shop:hover {
    transform: translate(3px, 3px);
  }
}

@media only screen and (max-width: 950px) {
  .container {
    margin-bottom: 170px;
    flex-direction: column;
    background: linear-gradient(153.46deg, #20B270 32.24%, #22AB91 59.69%, #00A4B2 87.13%);

    .main-text {
      .header {
        font-size: 36px;
        text-align: center;
      }

      .description {
        font-size: 14px;
        text-align: center;
      }

      a {
        width: 200px;
        margin: 0 auto;
      }

      .shop_buttons {
        flex-direction: column;
      }
    }
  }

  .phones {
    margin-top: 30px;
    margin-bottom: -140px;
    width: 320px;
  }
}
</style>
