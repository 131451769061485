<template>
  <!-- eslint-disable max-len -->
  <div class="container">
    <!--<div class="info">© My Friends Social Limited under Spark Star LTD license 2009-2023 All rights
      reserved.
    </div>-->
    <div class="info">© Innomedia Software Solutions FZ-LLC 2023 All rights
      reserved.
    </div>
    <div class="links">
      <router-link to="/ua">User Agreements</router-link>
      <router-link to="/privacy">Privacy Policy</router-link>
      <!-- <router-link to="/streamers_rules">Streamers Rules</router-link> -->
      <!-- <router-link to="/broadcast">Broadcast</router-link> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;

  .links {
    display: flex;
    flex-direction: row;
    gap: 20px;

    a {
      word-wrap: break-word;
      text-decoration: none;
      color: #18ac6c;
    }
  }
}

@media only screen and (max-width: 950px) {
  .container {
    padding: 0 35px;
    margin-bottom: 50px;
  }
}
</style>
