import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import UserAgreementView from '../views/UserAgreementView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import StreamersRulesView from '../views/StreamersRulesView.vue';
import BroadcastView from '../views/BroadcastView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/ua',
    name: 'user agreements',
    component: UserAgreementView,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicyView,
  },
  {
    path: '/streamers_rules',
    name: 'rules',
    component: StreamersRulesView,
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    component: BroadcastView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
