<template>
  <!-- eslint-disable max-len -->
  <router-view />
</template>

<script lang="ts">

</script>

<style lang="scss">
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"),
    url(./fonts/Roboto-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}
</style>
