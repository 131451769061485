import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GreenHeader = _resolveComponent("GreenHeader")!
  const _component_GreenBody = _resolveComponent("GreenBody")!
  const _component_ContactsInfo = _resolveComponent("ContactsInfo")!
  const _component_HorizontalLine = _resolveComponent("HorizontalLine")!
  const _component_LinksFooter = _resolveComponent("LinksFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GreenHeader),
    _createVNode(_component_GreenBody),
    _createVNode(_component_ContactsInfo),
    _createVNode(_component_HorizontalLine),
    _createVNode(_component_LinksFooter)
  ]))
}