<template>
    <div class="container">
        <router-link to="/"><img src="../assets/logo.png" alt=""></router-link>
        <div class="brand">MyFriends Live</div>
    </div>
</template>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 5px 100px;
    color: white;
    background: linear-gradient(115.69deg, #20B270 33.71%, #22AB91 62.29%, #00A4B2 90.87%);

    img {
        width: 100px;
    }

    .brand {
        font-size: 32px;
        font-weight: bold;
    }
}

@media only screen and (max-width: 950px) {
  .container {
    background: #20B270;
    padding: 0;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 35px;
    gap: 0px;

    img {
      width: 60px;
      margin-left: -17px;
    }

    .brand {
      font-size: 24px;
      font-weight: bold;
      margin-left: -3px;
      margin-right: 3px;
      margin-top: -3px;
    }
  }
}

</style>
