<template>
    <!-- eslint-disable max-len -->
    <div class="container">
        <div>Contact Us</div>
        <div>
            If you have any questions, please email us at <a href="mailto:support@myfriends.com">support@myfriends.com</a>
        </div>
    </div>
</template>

<style scoped lang="scss">
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    div:first-child {
        font-family: "Roboto Bold";
    }

    a {
        word-wrap: break-word;
        text-decoration: none;
        color: #18ac6c;
    }
}

@media only screen and (max-width: 950px) {
  .container {
    padding: 0 35px;
  }
}

</style>
