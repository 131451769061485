
import { defineComponent } from 'vue';
import GreenHeader from '@/components/GreenHeader.vue';
import LinksFooter from '@/components/LinksFooter.vue';
import HorizontalLine from '@/components/HorizontalLine.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    GreenHeader,
    LinksFooter,
    HorizontalLine,
  },
});
